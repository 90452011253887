import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import Loader from './Loader'
import { checkRequestStatus, requestNumber } from '../actions/requestActions'
import AlertMsg from './AlertMsg'

const RequestForm = ({ receiver }) => {
  const dispatch = useDispatch()
  const [requestMessage, setRequestMessage] = useState(
    'Hi, can I get your number?'
  )

  const numberRequest = useSelector((state) => state.numberRequest)
  const { loading, error, requestDetail, success } = numberRequest

  const requestCheck = useSelector((state) => state.requestCheck)
  const {
    loading: loadingStatus,
    requestStatus,
    error: errorStatus,
  } = requestCheck

  const renderStatus = () => {
    if (requestStatus) {
      switch (requestStatus.status) {
        case 'Accepted':
          return (
            <h4>
              <i class='fa-solid fa-phone' style={{ color: 'green' }}></i>{' '}
              {requestStatus?.requestExists[0]?.receiver?.phone}
            </h4>
          )
        case 'Pending':
          return (
            <AlertMsg variant='dark'>
              Your request is already pending with this person to either accept
              or reject it.
            </AlertMsg>
          )
        case 'Rejected':
          return (
            <AlertMsg variant='light'>
              <i className='fa-solid fa-square-xmark fa-xl red-color'></i> This
              person has rejected your request.
            </AlertMsg>
          )
        default:
          return null
      }
    }
  }

  useEffect(() => {
    dispatch(checkRequestStatus(receiver))
  }, [dispatch, receiver])

  const handleSubmit = (e) => {
    e.preventDefault()
    const message = requestMessage
    const status = 'Pending'
    dispatch(requestNumber({ receiver, message, status }))
  }

  return (
    <>
      {loadingStatus ? (
        <Loader />
      ) : errorStatus ? (
        <AlertMsg>{errorStatus}</AlertMsg>
      ) : requestStatus?.status !== null ? (
        renderStatus()
      ) : loading ? (
        <Loader />
      ) : success ? (
        <AlertMsg variant='success'>
          Your request has been successfully sent.
        </AlertMsg>
      ) : (
        <Form>
          {error && <AlertMsg>{error}</AlertMsg>}
          <Form.Group controlId='message'>
            <Form.Label>
              Message <span className='text-muted'>(optional)</span>
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={2}
              type='textarea'
              placeholder='Hi, Can I get your number?'
              onChange={(e) => setRequestMessage(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='submit'>
            <Button
              type='submit'
              className='my-2'
              onClick={handleSubmit}
              variant='primary'
            >
              Send Request
            </Button>
          </Form.Group>
        </Form>
      )}
    </>
  )
}

export default RequestForm
