import React from 'react'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { requestAction } from '../actions/requestActions'
import Moment from 'react-moment'
const Numbers = ({ request, who }) => {
  const dispatch = useDispatch()
  //console.log(request, who);
  if(who==='receiver'){
  return (
    <Card className='mb-3 rounded'>
      <Row className='g-0'>
        <Col md={5}>
          <Link to={`/profile/${request.sender?._id}`}>
            <Card.Img variant='top' src={request.sender?.photos[0].value} />
          </Link>
        </Col>
        <Col md={7}>
          <Card.Body>
            <Card.Title>
              <Link to={`/profile/${request.sender?._id}`}>
                {request.sender?.displayName}
              </Link>
            </Card.Title>
            <Card.Text>{request.message}</Card.Text>
            <Card.Text>
              <i class='fa-solid fa-phone' style={{ color: 'green' }}></i>{' '}
              <strong><a href={`tel:${request.sender?.phone}`}>{request.sender?.phone}</a></strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small>
              Action taken <Moment fromNow>{request.updatedAt}</Moment>
            </small>
          </Card.Footer>
        </Col>
      </Row>
    </Card>
  )
  }else if(who==='sender'){
    return (
      <Card className='mb-3 rounded'>
      <Row className='g-0'>
        <Col md={5}>
          <Link to={`/profile/${request.receiver?._id}`}>
            <Card.Img variant='top' src={request.receiver?.photos[0].value} />
          </Link>
        </Col>
        <Col md={7}>
          <Card.Body>
            <Card.Title>
              <Link to={`/profile/${request.receiver?._id}`}>
                {request.receiver?.displayName}
              </Link>
            </Card.Title>
            <Card.Text>{request.message}</Card.Text>
            <Card.Text>
              <i class='fa-solid fa-phone' style={{ color: 'green' }}></i>{' '}
              <strong><a href={`tel:${request.receiver?.phone}`}>{request.receiver?.phone}</a></strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small>
              Action taken <Moment fromNow>{request.updatedAt}</Moment>
            </small>
          </Card.Footer>
        </Col>
      </Row>
    </Card>
    )
  }
}

export default Numbers
