import { FETCH_USER } from '../constants/authConstants'

const authReducer = (state = null, action) => {
  //console.log(action);
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false
    default:
      return state
  }
}

export default authReducer
