export const REQUEST_NUMBER_REQUEST = 'REQUEST_NUMBER_REQUEST'
export const REQUEST_NUMBER_SUCCESS = 'REQUEST_NUMBER_SUCCESS'
export const REQUEST_NUMBER_FAIL = 'REQUEST_NUMBER_FAIL'
export const REQUEST_NUMBER_RESET = 'REQUEST_NUMBER_RESET'

export const REQUEST_CHECK_REQUEST = 'REQUEST_CHECK_REQUEST'
export const REQUEST_CHECK_SUCCESS = 'REQUEST_CHECK_SUCCESS'
export const REQUEST_CHECK_FAIL = 'REQUEST_CHECK_FAIL'
export const REQUEST_CHECK_RESET = 'REQUEST_CHECK_RESET'

export const REQUEST_ALL_REQUEST = 'REQUEST_ALL_REQUEST'
export const REQUEST_ALL_SUCCESS = 'REQUEST_ALL_SUCCESS'
export const REQUEST_ALL_FAIL = 'REQUEST_ALL_FAIL'
export const REQUEST_ALL_RESET = 'REQUEST_ALL_RESET'

export const REQUEST_ACTION_REQUEST = 'REQUEST_ACTION_REQUEST'
export const REQUEST_ACTION_SUCCESS = 'REQUEST_ACTION_SUCCESS'
export const REQUEST_ACTION_FAIL = 'REQUEST_ACTION_FAIL'
export const REQUEST_ACTION_RESET = 'REQUEST_ACTION_RESET'
