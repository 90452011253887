import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AlertMsg from '../components/AlertMsg'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getAllRequests } from '../actions/requestActions'
import Numbers from '../components/Numbers'
import {
  REQUEST_ACTION_RESET,
  REQUEST_ALL_RESET,
} from '../constants/requestConstants'

const ReceivedAcceptedScreen = () => {
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const requestAction = useSelector(state => state.requestAction)
  const { loadingAction, errorAction, requestActionResponse } = requestAction

  const allMyRequests = useSelector(state => state.allRequests)
  const { loading, error, receivedRequests } = allMyRequests
  
  useEffect(() => {
    if (auth === false) {
      navigate('/')
    }
    dispatch({ type: REQUEST_ALL_RESET })
    //TODO CLEAN UP
    if (auth && auth._id){
    dispatch(getAllRequests(auth && auth._id, 'Accepted'))
    }
  }, [auth,dispatch])

  return (
    <FormContainer>
      <h2>Received Accepted Requests</h2>
      {loadingAction  && <Loader />}
      {errorAction && <AlertMsg>{errorAction}</AlertMsg>}
      {message && <AlertMsg variant='success'>{message}</AlertMsg>}
      {(loading || loadingAction || !auth) ? (
        <Loader />
      ) : error ? (
        <AlertMsg>{error}</AlertMsg>
      ) : (
        <>
          {receivedRequests && receivedRequests.length > 0 ? (
            receivedRequests.map(request => <Numbers key={request._id} request={request} who='receiver' />)
          ) : (
            <AlertMsg variant='info'>
              You currently have not accepted any of the number requests that
              you have received.
            </AlertMsg>
          )}
        </>
      )}
    </FormContainer>
  )
}

export default ReceivedAcceptedScreen
