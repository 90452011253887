import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Welcome to smsBZ.com',
  description:
    'Connect to people for friendship, dating or business networking.',
  keywords:
    'phone numbers, sms, new friends, online dating, business networking',
}

export default Meta
