import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const trimName = chars => {
  if (chars.length > 10) {
    return chars.substring(0, 7) + '...'
  }
  return chars
}
const User = ({ user }) => {
  return (
    <Card className='my-3 rounded'>
      <Link to={`/profile/${user._id}`}>
        <Card.Img variant='top' src={user.photos[0].value} />
      </Link>
      <Card.Body>
        <Card.Title>{trimName(user.displayName)}</Card.Title>
        <Link
          to={`/profile/${user._id}`}
          className='btn btn-primary'
          variant='primary'
        >
          <i className='fas fa-sms'></i> Connect
        </Link>
      </Card.Body>
    </Card>
  )
}

export default User
