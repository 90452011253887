import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import AlertMsg from '../components/AlertMsg'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getAllRequests } from '../actions/requestActions'
import NumberRequest from '../components/NumberRequest'
import {
  REQUEST_ACTION_RESET,
  REQUEST_ALL_RESET,
} from '../constants/requestConstants'

const MainScreen = () => {
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  
  const allMyRequests = useSelector((state) => state.allRequests)
  const { loading, error, receivedRequests } = allMyRequests
  const requestAction = useSelector((state) => state.requestAction)
  const { loadingAction, errorAction, requestActionResponse } = requestAction

  useEffect(() => {
    
    if (auth === false) {
      navigate('/')
    }
    
    if (requestActionResponse) {
      setMessage(
        `You've ${requestActionResponse.status} ${requestActionResponse.sender.displayName}'s number request.`
      )
      dispatch({ type: REQUEST_ACTION_RESET })
      dispatch({ type: REQUEST_ALL_RESET })
    }
    if (auth && auth._id){
    dispatch(getAllRequests(auth && auth._id))
    }
  }, [dispatch, requestActionResponse, auth])

  return (
    <FormContainer>
      <h2>Recent Requests</h2>
      {loadingAction && <Loader />}
      {errorAction && <AlertMsg>{errorAction}</AlertMsg>}
      {message && <AlertMsg variant='success'>{message}</AlertMsg>}
      {loading ? (
        <Loader />
      ) : error ? (
        <AlertMsg>{error}</AlertMsg>
      ) : (
        <>
          {receivedRequests && receivedRequests.length > 0 ? (
            receivedRequests.map((request) => (
              <NumberRequest request={request} />
            ))
          ) : (
            <AlertMsg variant='info'>
              You currently do not have any pending number requests. <Link to="/"> Checkout profiles. </Link>
            </AlertMsg>
          )}
        </>
      )}
    </FormContainer>
  )
}

export default MainScreen
