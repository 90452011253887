import axios from 'axios'
import {
  REQUEST_ACTION_FAIL,
  REQUEST_ACTION_REQUEST,
  REQUEST_ACTION_SUCCESS,
  REQUEST_ALL_FAIL,
  REQUEST_ALL_REQUEST,
  REQUEST_ALL_SUCCESS,
  REQUEST_CHECK_FAIL,
  REQUEST_CHECK_REQUEST,
  REQUEST_CHECK_SUCCESS,
  REQUEST_NUMBER_FAIL,
  REQUEST_NUMBER_REQUEST,
  REQUEST_NUMBER_SUCCESS,
} from '../constants/requestConstants'

export const requestNumber = request => async dispatch => {
  try {
    dispatch({ type: REQUEST_NUMBER_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(`/api/request/number`, request, config)

    dispatch({
      type: REQUEST_NUMBER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_NUMBER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const checkRequestStatus = receiverId => async dispatch => {
  try {
    dispatch({ type: REQUEST_CHECK_REQUEST })

    const { data } = await axios.get(`/api/request/status/${receiverId}`)

    dispatch({
      type: REQUEST_CHECK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    
    dispatch({
      type: REQUEST_CHECK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
// userId : is the ID of either sender or receiver which is specified by the who variable which is by default set to receiver, statusType is the status for the request
export const getAllRequests =
  (userId, statusType = 'Pending', who = 'receiver') =>
  async dispatch => {
    try {
      dispatch({ type: REQUEST_ALL_REQUEST })

      const { data } = await axios.get(
        `/api/request/all/${userId}/${statusType}/${who}`
      )

      dispatch({
        type: REQUEST_ALL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: REQUEST_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const requestAction = action => async dispatch => {
  try {
    dispatch({ type: REQUEST_ACTION_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.patch(`/api/request/action`, action, config)

    dispatch({
      type: REQUEST_ACTION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_ACTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
