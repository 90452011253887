import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userListReducer,
  userDetailReducer,
  updateProfileReducer,
  userRegisterReducer,
  verifyStartReducer,
  verifyCodeReducer,
  verifyMobileAppReducer,
} from './reducers/userReducers'
import {
  numberRequestReducer,
  requestCheckReducer,
  allRequestsReducer,
  requestActionReducer,
} from './reducers/requestReducers'
import authReducer from './reducers/authReducer'

const reducer = combineReducers({
  auth: authReducer,
  userList: userListReducer,
  userDetail: userDetailReducer,
  userRegister: userRegisterReducer,
  updateProfile: updateProfileReducer,
  numberRequest: numberRequestReducer,
  requestCheck: requestCheckReducer,
  allRequests: allRequestsReducer,
  requestAction: requestActionReducer,
  verifyStart: verifyStartReducer,
  verifyCode: verifyCodeReducer,
  verifyMobileApp: verifyMobileAppReducer,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
