import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProfileScreen from './screens/ProfileScreen'
import { fetchUser } from './actions/authAction'
import RegisterScreen from './screens/RegisterScreen'
import MainScreen from './screens/MainScreen'
import ReceivedAcceptedScreen from './screens/ReceivedAcceptedScreen'
import SentAcceptedScreen from './screens/SentAcceptedScreen'
import VerifyPhoneScreen from './screens/VerifyPhoneScreen'
const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUser())
  }, [dispatch])

  return (
    <>
      <Header />
      <main className='py-3'>
        <Container>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/profile/:id' element={<ProfileScreen />} />
            <Route path='/register' element={<RegisterScreen />} />
            <Route path='/main' element={<MainScreen />} />
            <Route path='/numverify' element={<VerifyPhoneScreen />} />
            <Route
              path='/received/accepted'
              element={<ReceivedAcceptedScreen />}
            />
            <Route
              path='/sent/accepted'
              element={<SentAcceptedScreen />}
            />
          </Routes>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default App
