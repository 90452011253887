import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import Loader from './Loader'

const Header = () => {
  const auth = useSelector((state) => state.auth)

  // <NavDropdown title={userInfo.name} id='username'>
  //                 <LinkContainer to='/profile'>
  //                   <NavDropdown.Item>Profile</NavDropdown.Item>
  //                 </LinkContainer>
  //                 <NavDropdown.Item onClick={logoutHandler}>
  //                   Logout
  //                 </NavDropdown.Item>
  //               </NavDropdown>
  const renderContent = () => {
    switch (auth) {
      case null:
        return <Loader />
      case false:
        return (
          <Nav className='d-flex'>
            <a className='nav-link' href='/auth/google'>
              {/* <i className='fa-brands fa-google'></i> Login with Google */}
              <img
                src='/images/btn_google_dark_normal_ios.png'
                alt='login with google'
              />{' '}
              Login with Google
            </a>
          </Nav>
        )
      default:
        //<i class="fa fa-sign-out" aria-hidden="true"></i>
        return (
          <Nav className='d-flex'>
            <Nav.Link href='/main'>
              <i class='fa fa-user' aria-hidden='true'></i> {auth.displayName}
            </Nav.Link>
            <Nav.Link href='/received/accepted'>
            <i class="fas fa-envelope"></i> Received
            </Nav.Link>
            <Nav.Link href='/sent/accepted'>
            <i class="fas fa-paper-plane"></i> Sent
            </Nav.Link>
            <a className='nav-link' href='/auth/logout'>
              <i class='fa fa-sign-out' aria-hidden='true'></i> Logout
            </a>
          </Nav>
        )
    }
  }

  return (
    <header>
      <Navbar bg='light' expand='lg'>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img
                src='/images/smsBZ_logo.png'
                alt='smsBZ Logo'
                height='40'
                
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='me-auto my-2 my-lg-0'
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {/* <Nav.Link href='#action1'>Home</Nav.Link>
              <Nav.Link href='#action2'>Link</Nav.Link> */}
            </Nav>
            <Nav className='d-flex'>{renderContent()}</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
