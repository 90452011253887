import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap'
import Loader from '../components/Loader'
import AlertMsg from '../components/AlertMsg'
import RequestForm from '../components/RequestForm'
import { fetchUserDetail } from '../actions/userActions'
import capitalize from '../utils/capitalize'
import {
  REQUEST_CHECK_RESET,
  REQUEST_NUMBER_RESET,
} from '../constants/requestConstants'
import Meta from '../components/Meta'

const ProfileScreen = () => {
  const [showRequestForm, setShowRequestForm] = useState(false)

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const userDetails = useSelector((state) => state.userDetail)
  const { loading, user, error } = userDetails

  useEffect(() => {
    dispatch(fetchUserDetail(params.id))
  }, [dispatch, params.id])

  const handleGetNumberBtn = (e) => {
    dispatch({ type: REQUEST_NUMBER_RESET })
    dispatch({ type: REQUEST_CHECK_RESET })
    setShowRequestForm(true)
  }

  return (
    <>
      <Button className='btn btn-light' onClick={() => navigate(-1)}>
        Go Back
      </Button>
      {loading ? (
        <Loader />
      ) : error ? (
        <AlertMsg>{error}</AlertMsg>
      ) : (
        <Row>
          <Meta title={`${user.displayName}`} />
          <Col sm={12} md={3} lg={3} xl={3}>
            <Image
              src={user && user.photos && user.photos[0].value}
              alt={user && user.displayName}
              style={{ borderRadius: '50%', width: '100%' }}
              fluid
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <h3> {user.displayName}</h3>
            <ListGroup variant='flush'>
              <ListGroupItem>{user.bio}</ListGroupItem>
              <ListGroupItem>Age: {user.age}</ListGroupItem>
              <ListGroupItem>Gender: {user.gender}</ListGroupItem>
              <ListGroupItem>Looking for: {user.seeking}</ListGroupItem>
              <ListGroupItem>Occupation: {user.occupation}</ListGroupItem>
              <ListGroupItem>
                Location: {user.location && capitalize(user.location)}
              </ListGroupItem>
              <ListGroupItem>
                I also use: {user.whatsapp && <span><i className='fa-brands fa-whatsapp'></i>  WhatsApp  </span>} 
               
                {user.telegram && <span><i className='fa-brands fa-telegram'></i>   Telegram </span>} 
                {user.snapchat && <span><i className='fa-brands fa-snapchat'></i>        Snapchat  </span>} 
                {user.skype && <span><i className='fa-brands fa-skype'></i>  Skype  </span>} 
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col sm={12} md={3} lg={3} xl={3}>
            <ListGroup variant='flush'>
              <ListGroupItem>
                <h4>My phone number:</h4>
              </ListGroupItem>
              <ListGroupItem>
                {showRequestForm ? (
                  <RequestForm receiver={user._id} />
                ) : (
                  <h4>
                    <Button onClick={handleGetNumberBtn} variant='primary'>
                      <i
                        className='fa-solid fa-phone'
                        style={{ color: 'white' }}
                      ></i>{' '}
                      Get My Phone Number
                    </Button>
                  </h4>
                )}
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ProfileScreen
