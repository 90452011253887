import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import AlertMsg from '../components/AlertMsg'
import User from '../components/User'
import { listUsers } from '../actions/userActions'
import Meta from '../components/Meta'

const HomeScreen = () => {
  const dispatch = useDispatch()

  const userList = useSelector(state => state.userList)

  const { loading, error, users } = userList

  useEffect(() => {
    dispatch(listUsers())
  }, [dispatch])

  return (
    <>
      <Meta />
      <h1>Latest Users</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <AlertMsg>{error}</AlertMsg>
      ) : (
        <Row>
          {users.map(user => (
            <Col sm={12} md={4} lg={3} xl={2} key={user._id}>
              <User user={user} />
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default HomeScreen
