import React from 'react'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { requestAction } from '../actions/requestActions'
const NumberRequest = ({ request }) => {
  const dispatch = useDispatch()

  const handleAccept = () => {
    if (request) {
      dispatch(
        requestAction({ actionType: 'Accepted', requestId: request._id })
      )
    }
  }

  const handlReject = () => {
    if (request) {
      dispatch(
        requestAction({ actionType: 'Rejected', requestId: request._id })
      )
    }
  }

  return (
    <Card className='mb-3 rounded'>
      <Row className='g-0'>
        <Col md={5}>
          <Link to={`/profile/${request.sender._id}`}>
            <Card.Img variant='top' src={request.sender.photos[0].value} />
          </Link>
        </Col>
        <Col md={7}>
          <Card.Body>
            <Card.Title>
              <Link to={`/profile/${request.sender._id}`}>
                {request.sender.displayName}
              </Link>
            </Card.Title>
            <Card.Text>{request.message}</Card.Text>
            <Button
              className='btn btn-primary'
              variant='primary'
              onClick={handleAccept}
            >
              <i className='fa-solid fa-check'></i> Accept
            </Button>
            <Button
              className='btn btn-secondary mx-2'
              variant='secondary'
              onClick={handlReject}
            >
              <i className='fa-solid fa-xmark'></i> Reject
            </Button>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
}

export default NumberRequest
