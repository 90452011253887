export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL'

export const VERIFY_START_REQUEST = 'VERIFY_START_REQUEST'
export const VERIFY_START_SUCCESS = 'VERIFY_START_SUCCESS'
export const VERIFY_START_FAIL = 'VERIFY_START_FAIL'
export const VERIFY_START_RESET = 'VERIFY_START_RESET'

export const VERIFYCODE_START_REQUEST = 'VERIFYCODE_START_REQUEST'
export const VERIFYCODE_START_SUCCESS = 'VERIFYCODE_START_SUCCESS'
export const VERIFYCODE_START_FAIL = 'VERIFYCODE_START_FAIL'

export const VERIFYAPP_START_REQUEST = 'VERIFYAPP_START_REQUEST'
export const VERIFYAPP_START_SUCCESS = 'VERIFYAPP_START_SUCCESS'
export const VERIFYAPP_START_FAIL = 'VERIFYAPP_START_FAIL'
