import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import CountrySelect from 'react-bootstrap-country-select'
import AlertMsg from '../components/AlertMsg'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { registerUser } from '../actions/userActions'
import { Typeahead } from 'react-bootstrap-typeahead'
import professions from '../data/professions'
import calcAge from '../utils/calcAge'
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Meta from '../components/Meta'

const RegisterScreen = () => {
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [message, setMessage] = useState('')

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
    // Check and see if errors exist and remove them from the error object
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)
  
  const userRegister = useSelector(state => state.userRegister)
  const { loading, error, userInfo, success } = userRegister

  useEffect(() => {
    if (auth === false) {
      navigate('/')
    }
    
    if(auth && auth.gender && auth.location && !auth.numberVerified){
      navigate('/numverify')
    }
    if (userInfo && success) {
      navigate('/numverify')
    }
    if(auth && auth.age && auth.seeking && auth.numberVerified){
      navigate('/main')
    }

  }, [userInfo, success, navigate, auth])

  const validateForm = () => {
    const { dob, gender, location, occupation, seeking, bio, tosPrivacy } = form
    const newErrors = {}

    if (!dob || dob === '') newErrors.dob = 'Please enter your date of birth.'
    else if (calcAge(dob) < 18)
      newErrors.dob = 'You need to be atleast 18 years old to use this service.'
    if (!gender || gender === 'Select Gender')
      newErrors.gender = 'Please select gender'
    if (!location || location === '')
      newErrors.location = 'Please choose your location'
    if (!occupation || occupation === '')
      newErrors.occupation = 'Please select your occupation'
    if (!seeking || seeking === 'What are you looking for?')
      newErrors.seeking = 'Please select what you are seeking for?'
    if (!bio || bio === '')
      newErrors.bio = 'Please write something about yourself.'
    else if (bio.length < 10) newErrors.bio = 'Bio is too short'

    if(!tosPrivacy || tosPrivacy === false){
      newErrors.tosPrivacy = "You must accept the terms of service."
    }

    return newErrors
  }

  
  const handleSubmit = e => {
    e.preventDefault()
    setMessage('')
    //get our new errors
    const formErrors = validateForm()

    //check for errors

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      setMessage('Whoops, please check for errors below highlighted in red.')
    } else {
      //console.log(form)
      //no errors! submit the form
      dispatch(registerUser(form))
    }
  }

  return (
    <FormContainer>
      <Meta title='Complete your profile' />
      <h2>Complete your profile</h2>
      <p><i className='fas fa-info-circle fa-sm grey-color'></i> Please complete registration to activate your profile.</p>
      {message && <AlertMsg>{message}</AlertMsg>}
      {error && <AlertMsg>{error}</AlertMsg>}
      {loading && <Loader />}
      <Form>
        <Form.Group controlId='dob'>
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter date of birth'
            onChange={e => {
              //console.log(e.target.value)
              setField('dob', e.target.value)
            }}
            isInvalid={!!errors.dob}
          ></Form.Control>
          <Form.Control.Feedback type='invalid'>
            {errors.dob}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='gender'>
          <Form.Label>Gender</Form.Label>
          <Form.Select
            value={form.gender}
            isInvalid={!!errors.gender}
            placeholder='Select Gender'
            onChange={e => {
              setField('gender', e.target.value)
            }}
          >
            <option>Select Gender</option>
            <option value='M'>Male</option>
            <option value='F'>Female</option>
          </Form.Select>
          <Form.Control.Feedback type='invalid'>
            {errors.gender}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='country'>
          <Form.Label>Location</Form.Label>
          <CountrySelect
            className={!!errors.location && 'red-border'}
            id='country'
            autoComplete='off'
            throwInvalidValueError={true}
            required
            valueAs='object'
            value={form.location}
            onChange={selected => {
              //console.log(selected)
              setField('location', selected)
            }}
          />
          <div className='red'>{errors.location}</div>
        </Form.Group>
        <Form.Group controlId='occupation'>
          <Form.Label>Occupation/Profession</Form.Label>
          <Typeahead
            id='occupation'
            name='occupation'
            onChange={selected => {
              //console.log(selected)
              //console.log('get value out', selected[0])
              setField('occupation', selected && selected[0])
            }}
            className={!!errors.occupation && 'red-border'}
            inputProps={{ required: true }}
            placeholder='Choose a profession...'
            options={professions}
          />
          <div className='red'>{errors.occupation}</div>
        </Form.Group>
        <Form.Group controlId='seeking'>
          <Form.Label>Seeking</Form.Label>
          <Form.Select
            type='text'
            placeholder='Enter Seeking'
            value={form.seeking}
            isInvalid={!!errors.seeking}
            onChange={e => setField('seeking', e.target.value)}
          >
            <option>What are you looking for?</option>
            <option value='Dating'>Dating</option>
            <option value='Friendship'>Friendship</option>
            <option value='Business Networking'>Business Networking</option>
          </Form.Select>
          <Form.Control.Feedback type='invalid'>
            {errors.seeking}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='bio'>
          <Form.Label>Short Bio / Description</Form.Label>
          <Form.Control
            as='textarea'
            rows={2}
            type='textarea'
            placeholder='Enter Short Bio / Description'
            value={form.bio}
            isInvalid={!!errors.bio}
            onChange={e => setField('bio', e.target.value)}
          ></Form.Control>
          <Form.Control.Feedback type='invalid'>
            {errors.bio}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='tosPrivacy'>
          <p>{' '}</p>
          <Form.Check
          type="checkbox"
          checked={form.tosPrivacy}
          isInvalid={!!errors.tosPrivacy}
          onChange={e => setField('tosPrivacy', e.target.checked)}
          label={
            <>
              I agree to the{' '}
              <a href="#" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="#" target="_blank" rel="noopener noreferrer">
                Privacy Notice
              </a>
            </>
          } />
          
          <div className='red'>
            {errors.tosPrivacy}
          </div>
          </Form.Group>
        <Form.Group controlId='submit'>
          <Button
            type='submit'
            className='my-2'
            onClick={handleSubmit}
            variant='primary'
          >
            Continue
          </Button>
        </Form.Group>
      </Form>
    </FormContainer>
  )
}

export default RegisterScreen
