import {
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  VERIFYAPP_START_FAIL,
  VERIFYAPP_START_REQUEST,
  VERIFYAPP_START_SUCCESS,
  VERIFYCODE_START_FAIL,
  VERIFYCODE_START_REQUEST,
  VERIFYCODE_START_SUCCESS,
  VERIFY_START_FAIL,
  VERIFY_START_REQUEST,
  VERIFY_START_RESET,
  VERIFY_START_SUCCESS,
} from '../constants/userConstants'

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDetailReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return { loading: true, user: {} }
    case USER_DETAIL_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST:
      return { loading: true }
    case PROFILE_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const verifyStartReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_START_REQUEST:
      return { loading: true }
    case VERIFY_START_SUCCESS:
      return { loading: false, success: true, verifyInfo: action.payload }
    case VERIFY_START_FAIL:
      return { loading: false, error: action.payload }
    case VERIFY_START_RESET:
      return {}
    default:
      return state
  }
}

export const verifyCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFYCODE_START_REQUEST:
      return { loading: true }
    case VERIFYCODE_START_SUCCESS:
      return {
        loading: false,
        success: true,
        verificationCode: action.payload,
      }
    case VERIFYCODE_START_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const verifyMobileAppReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFYAPP_START_REQUEST:
      return { loading: true }
    case VERIFYAPP_START_SUCCESS:
      return { loading: false, mobileApp: action.payload }
    case VERIFYAPP_START_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
