import {
  REQUEST_ACTION_FAIL,
  REQUEST_ACTION_REQUEST,
  REQUEST_ACTION_RESET,
  REQUEST_ACTION_SUCCESS,
  REQUEST_ALL_FAIL,
  REQUEST_ALL_REQUEST,
  REQUEST_ALL_RESET,
  REQUEST_ALL_SUCCESS,
  REQUEST_CHECK_FAIL,
  REQUEST_CHECK_REQUEST,
  REQUEST_CHECK_RESET,
  REQUEST_CHECK_SUCCESS,
  REQUEST_NUMBER_FAIL,
  REQUEST_NUMBER_REQUEST,
  REQUEST_NUMBER_RESET,
  REQUEST_NUMBER_SUCCESS,
} from '../constants/requestConstants'
export const numberRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_NUMBER_REQUEST:
      return { loading: true }
    case REQUEST_NUMBER_SUCCESS:
      return { loading: false, success: true, requestDetail: action.payload }
    case REQUEST_NUMBER_FAIL:
      return { loading: false, error: action.payload }
    case REQUEST_NUMBER_RESET:
      return {}
    default:
      return state
  }
}

export const requestCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CHECK_REQUEST:
      return { loading: true }
    case REQUEST_CHECK_SUCCESS:
      return { loading: false, requestStatus: action.payload }
    case REQUEST_CHECK_FAIL:
      return { loading: false, error: action.payload }
    case REQUEST_CHECK_RESET:
      return {}
    default:
      return state
  }
}

export const allRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALL_REQUEST:
      return { loading: true }
    case REQUEST_ALL_SUCCESS:
      return { loading: false, receivedRequests: action.payload }
    case REQUEST_ALL_FAIL:
      return { loading: false, error: action.payload }
    case REQUEST_ALL_RESET:
      return {}
    default:
      return state
  }
}
export const requestActionReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ACTION_REQUEST:
      return { loading: true }
    case REQUEST_ACTION_SUCCESS:
      return { loading: false, requestActionResponse: action.payload }
    case REQUEST_ACTION_FAIL:
      return { loading: false, error: action.payload }
    case REQUEST_ACTION_RESET:
      return {}
    default:
      return state
  }
}
