import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import AlertMsg from '../components/AlertMsg'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  verifyStart,
  verifyCode,
  mobileAppAction,
} from '../actions/userActions'
import { VERIFY_START_RESET } from '../constants/userConstants'
import Meta from '../components/Meta'

const VerifyPhoneScreen = () => {
  const [form, setForm] = useState({})
  const [code, setCode] = useState('')
  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [whatsapp, setWhatsapp] = useState(false)
  const [telegram, setTelegram] = useState(false)
  const [snapchat, setSnapchat] = useState(false)
  const [skype, setSkype] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)

  const verifyStartState = useSelector(state => state.verifyStart)
  const { loading, error, verifyInfo, success } = verifyStartState

  const verifyCodeState = useSelector(state => state.verifyCode)
  const {
    loading: loadingVerify,
    error: errorVerify,
    verificationCode,
    success: successVerify,
  } = verifyCodeState

  useEffect(() => {
    // if (auth === false) {
    //   navigate('/')
    // }

    if (success) {
      setSuccessMsg('')
      setSuccessMsg('Verfication code sent successfully! Enter it below.')
    }
    if (successVerify) {
      setSuccessMsg('')
      setSuccessMsg(
        'Your phone number has been successfully verified and your profile is active now.'
      )
    }
  }, [verifyInfo, success, verificationCode, successVerify, navigate, auth])

  const handlePhoneNumberChange = (
    isValid,
    rawValue,
    countryData,
    formattedValue,
    extension
  ) => {
    setForm({ isValid, rawValue, countryData, formattedValue, extension })
  }
  const handleSubmit = e => {
    e.preventDefault()
    setMessage('')
    setSuccessMsg('')
    //get our new errors

    //check for errors

    if (form.isValid) {
      dispatch(
        verifyStart({
          phoneNumber: form.formattedValue,
          via: 'sms',
          country: form.countryData.name,
        })
      )
    } else {
      setMessage(
        'Whoops, your phone number appears to be invalid. Please fix it and try again'
      )
    }
  }

  const handleVerification = e => {
    e.preventDefault()
    setMessage('')
    setSuccessMsg('')
    if (!code || code === '') {
      setMessage(
        `Whoops, you didn't enter code. Please input the verification code and try again.`
      )
    } else {
      dispatch(
        verifyCode({
          phoneNumber: form.formattedValue,
          token: code,
          country: form.countryData.name,
        })
      )
      dispatch({ type: VERIFY_START_RESET })
    }
  }

  const handleMobileApps = (app, val) => {
    console.log(`app ${app} val ${val}`)
    switch (app) {
      case 'whatsapp':
        setWhatsapp(val)
        dispatch(mobileAppAction({ app, val }))
        break
      case 'telegram':
        setTelegram(val)
        dispatch(mobileAppAction({ app, val }))
        break
      case 'snapchat':
        setSnapchat(val)
        dispatch(mobileAppAction({ app, val }))
        break
      case 'skype':
        setSkype(val)
        dispatch(mobileAppAction({ app, val }))
        break
    }
  }

  return (
    <FormContainer>
      <Meta title='Verify your phone number' />
      <h2>
        {successVerify
          ? `Congrats! you are done. Your profile has been activated.`
          : success
          ? `Verify Verification Code`
          : `Verify Phone Number To Activate Your Profile`}
      </h2>
      {successMsg && <AlertMsg variant='success'>{successMsg}</AlertMsg>}
      {message && <AlertMsg>{message}</AlertMsg>}
      {error && <AlertMsg>{error}</AlertMsg>}
      {errorVerify && <AlertMsg>{errorVerify}</AlertMsg>}
      {loading || loadingVerify ? (
        <Loader />
      ) : success || errorVerify ? (
        <Form>
          <p>Didn't receive verification code? <a href="/numverify">Try again</a></p>
          <Form.Group controlId='verifytoken'>
            <Form.Label>Verification Code: &nbsp;</Form.Label>
            <Form.Control
              type='number'
              required
              maxLength={6}
              placeholder='Enter Verification code here'
              value={code}
              onChange={e => setCode(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='submit'>
            <Button
              type='submit'
              className='my-2 justify-content-md-center'
              onClick={handleVerification}
              variant='primary'
            >
              Verify Phone
            </Button>
          </Form.Group>
        </Form>
      ) : successVerify ? (
        <Form>
          <h3>Do you use any of the following apps? </h3>
          <p>
            (Optional) Check any or all of them if the same phone number of
            yours is registered on the apps listed below:
          </p>
          <Form.Group controlId='WhatsApp'>
            <Form.Check
              type='switch'
              id='WhatsApp'
              label='WhatsApp'
              onChange={e => {
                handleMobileApps('whatsapp', !whatsapp)
              }}
            />
          </Form.Group>
          <Form.Group controlId='Telegram'>
            <Form.Check
              type='switch'
              id='Telegram'
              label='Telegram'
              onChange={e => {
                handleMobileApps('telegram', !telegram)
              }}
            />
          </Form.Group>
          <Form.Group controlId='SnapChat'>
            <Form.Check
              type='switch'
              id='SnapChat'
              label='SnapChat'
              onChange={e => {
                handleMobileApps('snapchat', !snapchat)
              }}
            />
          </Form.Group>
          <Form.Group controlId='Skype'>
            <Form.Check
              type='switch'
              id='Skype'
              label='Skype'
              onChange={e => {
                handleMobileApps('skype', !skype)
              }}
            />
          </Form.Group>
          <Form.Group controlId='submit'>
            <Button
              type='submit'
              className='my-2 justify-content-md-center'
              onClick={e => navigate('/')}
              variant='primary'
            >
              Finish
            </Button>
          </Form.Group>
          <small>
            Please note that we are not affiliated with WhatsApp, Telegram,
            Snapchat and Skype.{' '}
          </small>
        </Form>
      ) : (
        <Form>
          <Form.Group controlId='country'>
            <Form.Label>Your Phone Number: &nbsp;</Form.Label>
            <IntlTelInput
              required
              value={form.rawValue}
              onPhoneNumberChange={handlePhoneNumberChange}
            />
          </Form.Group>

          <Form.Group controlId='submit'>
            <Button
              type='submit'
              className='my-2 justify-content-md-center'
              onClick={handleSubmit}
              variant='primary'
            >
              Request Verification Code
            </Button>
          </Form.Group>
        </Form>
      )}
    </FormContainer>
  )
}

export default VerifyPhoneScreen
