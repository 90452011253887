import axios from 'axios'
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_FAIL,
  USER_DETAIL_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  VERIFY_START_REQUEST,
  VERIFY_START_SUCCESS,
  VERIFY_START_FAIL,
  VERIFYCODE_START_REQUEST,
  VERIFYCODE_START_SUCCESS,
  VERIFYCODE_START_FAIL,
  VERIFYAPP_START_REQUEST,
  VERIFYAPP_START_SUCCESS,
  VERIFYAPP_START_FAIL,
} from '../constants/userConstants'

export const listUsers = () => async dispatch => {
  try {
    dispatch({ type: USER_LIST_REQUEST })

    const { data } = await axios.get('/api/users')

    dispatch({ type: USER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const fetchUserDetail = id => async dispatch => {
  try {
    dispatch({ type: USER_DETAIL_REQUEST })

    const { data } = await axios.get(`/api/users/${id}`)

    dispatch({
      type: USER_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const registerUser = user => async dispatch => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(`/api/users/register`, user, config)

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUserProfile = user => async dispatch => {
  try {
    dispatch({ type: PROFILE_UPDATE_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({
      type: PROFILE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROFILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const verifyStart = phoneData => async dispatch => {
  try {
    dispatch({ type: VERIFY_START_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(`/api/verify/phone`, phoneData, config)

    dispatch({
      type: VERIFY_START_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VERIFY_START_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const verifyCode = phoneData => async dispatch => {
  try {
    dispatch({ type: VERIFYCODE_START_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(`/api/verify/token`, phoneData, config)

    dispatch({
      type: VERIFYCODE_START_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VERIFYCODE_START_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const mobileAppAction = appData => async dispatch => {
  try {
    dispatch({ type: VERIFYAPP_START_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(`/api/verify/app`, appData, config)

    dispatch({
      type: VERIFYAPP_START_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VERIFYAPP_START_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
